<template>
  <v-card
    width="270"
    outlined
  >
    <v-btn
      v-if="!plain"
      @click="$emit('remove')"
      class="mr-n6 mt-n3"
      depressed
      absolute
      x-small
      right
      fab
    >
      <v-icon small>mdi-close</v-icon>
    </v-btn>

    <v-card-text class="d-flex gap-7 align-center">
      <v-icon :color="fileIcon(file.type, true)" left>{{ fileIcon(file.type) }}</v-icon> 
      <div class="d-inline-block text-truncate" style="max-width: 150px;">
        <span>{{ file.name }}</span>
        <span class="caption info--text"><br> {{ file.size | fileSize }} ({{ file.type }})</span>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    file: null,
    plain: Boolean,
  },

  methods: {
    fileIcon(type, color = false) {
       let icons = [
        {
          icon: 'mdi-file-image-outline',
          color: 'green accent-4',
          types: [
            'image/jpeg',
            'image/png'
          ]
        },
        {
          icon: 'mdi-file-pdf-outline',
          color: 'red accent-3',
          types: [
            'application/pdf',
          ]
        },
        {
          icon: 'mdi-file-code-outline',
          color: 'amber darken-2',
          types: [
            'image/svg+xml',
          ]
        },
        {
          icon: 'mdi-zip-box-outline',
          color: 'blue-grey darken-1',
          types: [
            'application/x-zip-compressed',
          ]
        },
      ]

      let icon = icons.find(i => {
        return i.types.includes(type)
      })

      if (color) return icon ? icon.color : 'blue accent-3'
      else return icon ? icon.icon : 'mdi-file-document-outline'
    }
  }
}
</script>